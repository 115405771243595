import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'manage',
    loadChildren: () => import('../../manage/manage.module').then(m => m.ManageModule)
  },
  {
    path: 'manage-s',
    loadChildren: () => import('../../super-admin-components/super-admin-components.module').then(m => m.SuperAdminComponentsModule)
  },
  {
    path: 'subscription-plans',
    loadChildren: () => import('../../sub-plans/sub-plans.module').then(m => m.SubPlansModule)
  },
  {
    path: 'manage-o',
    loadChildren: () => import('../../org-admin-components/org-admin-components.module').then(m => m.OrgAdminComponentsModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../support/support.module').then(m => m.SupportModule)
  },

  // Clinic Admin
  {
    path: 'patient',
    loadChildren: () => import('../../Oncopay-Financial-Navigator/patient/patient.module').then(m => m.PatientModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('../../Oncopay-Financial-Navigator/reporting/reporting.module').then(m => m.ReportingModule)
  },
  {
    path: 'schedular',
    loadChildren: () => import('../../Oncopay-Financial-Navigator/schedular/schedular.module').then(m => m.SchedularModule)
  },
  {
    path: 'holiday',
    loadChildren: () => import('../../Oncopay-Financial-Navigator/holiday/holiday.module').then(m => m.HolidayModule)
  },
  {
    path: 'activity',
    loadChildren: () => import('../../Oncopay-Financial-Navigator/activity/activity.module').then(m => m.ActivityModule)
  },


  // General
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  },
   {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'chat-ngrx',
    loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  }
];
